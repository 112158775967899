<template>
    <div class="home">
        <!-- 头部 -->
        <!-- <largeHead :tabArrShow="tabArrShow"></largeHead> -->

        <!-- 面包屑 -->
        <div style="width: 1200px;height: auto;margin: 30px auto;">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">数控机床一站式服务平台首页</el-breadcrumb-item>
                <el-breadcrumb-item>网站公告</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!-- 内容列表 -->
        <div class="announList">
            <div class="title">网站公告</div>
            <div class="son" v-for="(i, index) in noticeList" :key="index" @click="announInfo(i)">
                <div class="tit">
                    <div class="head">{{ i.articleTitle }}</div>
                    <div class="time">{{ i.updateTime }}</div>
                </div>
                <div class="text">
                    {{ i.articleContent }}
                </div>
            </div>
        </div>

        <!-- 分页 -->
        <paging :total="paging.total" @handleCurrentChange="handleCurrentChange" :currentPage="paging.page"
            :page-size="paging.sizi"></paging>

        <!-- 底部 -->
        <!-- <bottomContent></bottomContent> -->
    </div>
</template>

<script>
import {getNoticeList,gettNoticeType} from "@/utils/api/homeApi/index"
import largeHead from "@/components/largeHead.vue";
import bottomContent from "@/components/bottomContent.vue";
import paging from "@/components/paging.vue";
export default {
    name: 'list',
    components: {
        largeHead,
        bottomContent,
        paging
    },
    data() {
        return {
            // 头部激活菜单
            tabArrShow: null,
            paging: {
                sizi: 10,
                page: 1,
                total: 0
            },
            noticeList:[]
        };
    },
    mounted(){
        this.getNoticeList()
    },
    methods: {
        announInfo(i) {
            this.$router.push(`/announcement/info?data=${JSON.stringify(i)}`)
        },
        // 分页事件
        handleCurrentChange(val) {
            this.loading = true
            this.paging.page = val
            this.getNoticeList()
        },
        // 公告列表
        async getNoticeList() {
            const res = await getNoticeList({
                current:this.paging.page,
                size:this.paging.sizi,
            })
            if (res.statusCode == 8201) {
                this.noticeList = res.data.records
                this.paging.total = res.data.total
                let ids = res.data.records.map(obj => obj.categoryId).join(",")
                const rej = await gettNoticeType(ids)
                this.noticeList.map(o => {
                    rej.data.map(p => {
                        if (o.categoryId == p.id) {
                            this.$set(o,'typeName',p.name)
                        }
                    })
                })
            }
        }
    }
}
</script>

<style lang="less" scoped>
.home {
    width: 100%;
    min-height: 100vh;
    background-color: #f5f5f5;

    // 列表
    >.announList {
        width: 1200px;
        height: auto;
        margin: 0 auto;
        background-color: #fff;
        padding: 17px 32px;
        box-sizing: border-box;
        margin-bottom: 20px;

        >.title {
            font-size: 24px;
            font-weight: bold;
            color: #333;
            margin-bottom: 20px;
        }

        >.son {
            width: 100%;
            height: 105px;
            padding: 15px;
            box-sizing: border-box;
            border: 1px solid #E0E0E0;
            margin-bottom: 20px;
            cursor: pointer;

            >.tit {
                width: 100%;
                height: auto;
                display: flex;
                justify-content: space-between;

                >.head {
                    font-size: 16px;
                    font-weight: bold;
                    color: #333;
                }

                >.time {
                    font-size: 12px;
                    color: #999;
                }
            }

            >.text {
                width: 100%;
                font-size: 14px;
                color: #666;
                margin-top: 15px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
            }
        }

    }

}

/deep/ .el-breadcrumb__inner.is-link:hover {
    color: #ff4242 !important;
}
</style>